import { render, staticRenderFns } from "./ЕditPageTournament.vue?vue&type=template&id=d9f3b514&scoped=true"
import script from "./ЕditPageTournament.vue?vue&type=script&lang=js"
export * from "./ЕditPageTournament.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./ЕditPageTournament.vue?vue&type=style&index=1&id=d9f3b514&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9f3b514",
  null
  
)

export default component.exports