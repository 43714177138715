<template>
  <div>
    <div class="">
      <div class="d-flex flex-wrap">
        <div class="mr-2">
          <label>Место</label>
          <b-form-input :value="$props.place" disabled></b-form-input>
        </div>
        <div class="mr-2">
          <label>Приз</label>
          <b-form-input
            @change="onChange($event)"
            :value="$props.dollars"
            placeholder="Введите количество очков"
          ></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TournamentAdapterMixin } from "@/mixins/Tournament/TournamentAdapterMixin";

export default {
  name: "AddTournamentPrizeForm",
  mixins: [TournamentAdapterMixin],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    place: {
      type: Number,
      default: 1,
    },
    dollars: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onChange(value) {
      this.changeTournamentPrize({
        id: this.$props.index,
        prize_data: {
          amount: Number.parseInt(value),
        },
      });
    },
  },
};
</script>
