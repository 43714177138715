<template>
  <div class="container-fluid">
    <Breadcrumbs main="Рейтинг" title="Мировой рейтинг" />
    <b-card>
      <RatingTab :data="tabList"></RatingTab>
      <header class="d-flex justify-content-between mt-3">
        <SearchRating class="w-25" />
        <Actualize :actualize-function="actualizeRating" />
      </header>
      <body class="d-flex mt-3">
      <b-table :items="teamsWorldRatingList" :fields="teamsWorldRatingFields" />
      </body>
      <PaginationRating :total="paginationTotal" class="mt-3 mb-3" />
    </b-card>
  </div>
</template>

<script>
import api from "@/api/api";

//components
import SearchRating from "@/pages/Rating/components/SearchRating.vue";
import PaginationRating from "@/pages/Rating/components/PaginationRating.vue";
import RatingTab from "@/pages/Rating/components/RatingTab.vue";
import Actualize from "@/pages/Rating/components/Actualize.vue";

export default {
  components: {
    RatingTab,
    SearchRating,
    PaginationRating,
    Actualize,
  },
  data() {
    return {
      tabList: [],
      teamsWorldRatingList: [],
      teamsWorldRatingFields: [
        {key: 'id', label: 'ID'},
        {key: 'place', label: 'Место'},
        {key: 'name', label: 'Название'},
        {key: 'rating', label: 'Рейтинг'},
        {key: 'country', label: 'Код страны'}
      ],
      paginationTotal: 0,
    };
  },
  watch: {
    "$route"(newRoute, oldRoute) {
      if (newRoute.params.region) {
        this.handleQueryChange();
      }
    },
  },
  methods: {
    // Обёртка для вызова функции актуализации
    async actualizeRating() {
      try {
        await api.worldTeamRating.actualize();
        console.log("Актуализация завершена");
      } catch (error) {
        console.error("Ошибка актуализации:", error);
      }
    },
    async handleQueryChange() {
      const queryParams = {
        region: this.$route.params.region,
        page: parseInt(this.$route.query.page),
      };
      if (this.$route.query.search) {
        queryParams['filter[team.name]'] = this.$route.query.search;
      }
      this.fetchTeamsWorldRating(queryParams);

    },
    formatData(data) {
      return Object.entries(data).map(([key, value]) => ({
        id: key,
        name: value,
        slug: key,
      }));
    },

    async fetchTeamsWorldRatingRegions() {
      try {
        const res = await api.worldTeamRating.regions();
        this.tabList = this.formatData(res.data.data);
      } catch (error) {
        console.error("Error fetching world rating regions:", error);
      }
    },
    async fetchTeamsWorldRating(queryParams = {}) {
      try {
        this.teamsWorldRatingList = [];
        const {region, ...params} = queryParams;
        console.log(params, 'oooooooo')
        const res = await api.worldTeamRating.index(region, params);
        this.paginationTotal = Math.ceil(res.data.pagination.total / res.data.pagination.by);
        this.teamsWorldRatingList = res.data.data.map(team => ({
          id: team.id,
          place: team.place,
          name: team.name,
          rating: team.rating,
          country: team.country.code,
        }));
      } catch (error) {
        console.error("Error fetching world rating:", error);
      }
    },
  },
  created() {
    const queryParams = {
      region: this.$route.params.region, // Регион из path
      page: parseInt(this.$route.query.page), // Устанавливаем page из query или по умолчанию 1
    };
    this.fetchTeamsWorldRatingRegions();
    this.fetchTeamsWorldRating(queryParams);
  }
};
</script>
